import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SiteLayoutComponent} from './shared/components/layouts/site-layout/site-layout.component';
import {LandingLayoutComponent} from './shared/components/layouts/landing-layout/landing-layout.component';
import {PanelLayoutComponent} from './shared/components/layouts/panel-layout/panel-layout.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {IsGuestGuard} from './shared/guards/is-guest.guard';
import { ProfileGuard } from './shared/guards/profile.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingLayoutComponent,
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {
        path: 'site',
        loadChildren: () => import('./pages/site/site.module').then(m => m.SiteModule),
      },
      {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
        canActivate: [IsGuestGuard],
      },
    ],
  },
  {
    path: '',
    component: PanelLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'app',
        canActivate: [ProfileGuard],
        loadChildren: () => import('./pages/app/app.module').then(m => m.AppModule),
      },
      {
        path: 'properties',
        canActivate: [ProfileGuard],
        loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
      },
    ],
  },
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

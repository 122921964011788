import {environment} from '../../../environments/environment';
import * as cloneDeep from 'lodash/cloneDeep';


export class Helper {

  static objectToQueryString(obj: object): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  static highlightElem(element): void {
    if (element != null) {
      element.classList.add('highlight');
      setTimeout(e => {
        element.classList.remove('highlight');
      }, 3000);
    }
  }

  static buildQuery(options, per_page, page_number): string {
    if (options == null) {
      /*options = new ApiParametersModel();*/
    }
    if (per_page == null) {
      per_page = environment.itemsPerPage;
    }
    let queryString = Helper.objectToQueryString(options.params);
    if (queryString !== '') {
      queryString = '?' + queryString + '&';
    } else {
      queryString = '?';
    }
    let sortParameterKey = 'sort';
    if (options.sortParam && options.sortParam !== '') {
      sortParameterKey = options.sortParam;
    }
    let extra_query = queryString + 'per-page=' + per_page + '&page=' + page_number;
    if (options.sort && options.sort !== '') {
      extra_query += '&' + sortParameterKey + '=' + options.sort;
    }
    if (options.expand && options.expand !== '') {
      extra_query += '&expand=' + options.expand;
    }
    return extra_query;
  }

  static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static printNumber(num: any): string {
    num = num * 1;
    return num.toLocaleString();
  }

  static printAmount(amount: any): any {
    if (amount !== '-') {
      return environment.currencyPrefix + this.printNumber(amount);
    } else {
      return '-';
    }
  }

  static assignSingleObject(data, objectRef): any[] {
    const cloneObj = cloneDeep(objectRef);
    Object.assign(cloneObj, data);
    return cloneObj;
  }

  static assignObject(items, objectRef): any[] {
    const results = [];
    for (const data of items) {
      const cloneObj = cloneDeep(objectRef);
      Object.assign(cloneObj, data);
      results.push(cloneObj);
    }
    return results;
  }

  static serialNumber(pageNumber, index, pageLength = null): number {
    if (pageLength == null) {
      pageLength = environment.itemsPerPage;
    }
    pageNumber = pageNumber * 1;
    index = index * 1;
    pageLength = pageLength * 1;
    const previousCount = (pageNumber - 1) * pageLength;
    index = index + previousCount;
    return index + 1;
  }

  static getSortColumn(newColumn: string, oldColumn: string): string {
    if (oldColumn === '') {
      return newColumn;
    } else if (oldColumn !== newColumn) {
      return newColumn;
    } else {
      const firstChar = oldColumn.charAt(0);
      if (firstChar !== '-') {
        return '-' + newColumn;
      } else {
        return newColumn;
      }
    }
  }

  static getGridFilterValues(parentElementSelector): any {
    const searchParams = {};
    const gridFilterElements = document.querySelectorAll(parentElementSelector + ' .gridfilter');
    if (gridFilterElements != null) {
      // @ts-ignore
      for (const gridFilterElement of gridFilterElements) {
        if (gridFilterElement != null) {
          searchParams[gridFilterElement.getAttribute('name')] = gridFilterElement.value;
        }
      }
    }
    return searchParams;
  }

  static yesNoBadge(value): string {
    value = value.toString();
    if (value === '1') {
      return '<span class="badge badge-success">Yes</span>';
    } else {
      return '<span class="badge badge-danger">No</span>';
    }
  }

  static viewFile(file: string): string {
    const protocal = file.substring(0, 4).toString().toLowerCase();
    if (protocal === 'http') {
      return file;
    }
    return environment.apiWebUrl + file;
  }

}

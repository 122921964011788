<div class="single-feature top-image-box">
  <div class="thumb"
       style="background-image: url('{{this.helper.viewFile(property?.featured_image)}}')"></div>
  <div class="details">
    <p class="author"><i class="fa fa-user"></i> {{property.user.getFullName()}}</p>
    <h6 class="title"><a routerLink="/site/property-details/{{property.id}}">{{property.name}}</a></h6>
    <h6 class="price">{{this.helper.printNumber(property.price)}} <span
      *ngIf="property.price_info!=null && property.price_info!='0'">/ {{property.price_info}}</span></h6>
    <ul class="info-list">
      <li *ngIf="this.commonService.ifNotNull(property?.bedrooms)"><i class="fa fa-bed"></i> {{property.bedrooms}} Bed
      </li>
      <li *ngIf="this.commonService.ifNotNull(property?.bathrooms)"><i class="fa fa-bath"></i> {{property.bathrooms}}
        Bath
      </li>
      <li *ngIf="this.commonService.ifNotNull(property?.area)"><img src="assets/img/icons/7.png"
                                                                    alt="img"> {{property.area}} sq.
      </li>
    </ul>
  </div>
</div>

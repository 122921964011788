import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoaderComponent} from './components/loader/loader.component';
import {RouterModule} from '@angular/router';
import {NgxEchartsModule} from 'ngx-echarts';
import {HelpBlockErrorComponent} from './components/form/help-block-error/help-block-error.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {FormsModule} from '@angular/forms';
import {SiteLayoutComponent} from './components/layouts/site-layout/site-layout.component';
import {LandingLayoutComponent} from './components/layouts/landing-layout/landing-layout.component';
import {HelpBlockErrorsComponent} from './components/form/help-block-errors/help-block-errors.component';
import {PanelLayoutComponent} from './components/layouts/panel-layout/panel-layout.component';
import {TableLoaderComponent} from './components/table-loader/table-loader.component';
import {PropertyBoxComponent} from './components/site/property-box/property-box.component';


@NgModule({
  declarations: [
    LoaderComponent,
    TableLoaderComponent,
    HelpBlockErrorComponent,
    HelpBlockErrorsComponent,
    PaginationComponent,
    SiteLayoutComponent,
    LandingLayoutComponent,
    PanelLayoutComponent,
    PropertyBoxComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgxEchartsModule,
    FormsModule
  ],
  providers: [],
  exports: [
    LoaderComponent,
    HelpBlockErrorComponent,
    PaginationComponent,
    HelpBlockErrorsComponent,
    TableLoaderComponent,
    PropertyBoxComponent,
  ],
})
export class SharedModule {
}

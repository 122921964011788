import {Component, OnInit} from '@angular/core';
import {ScriptService} from '../../../services/script.service';
import {AuthService} from '../../../services/auth.service';
import {CommonService} from "../../../services/common.service";

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
  providers: [ScriptService]
})
export class SiteLayoutComponent implements OnInit {

  settings: any = {};

  constructor(
    private scriptService: ScriptService,
    public authService: AuthService,
    public commonService: CommonService,
  ) {
    this.settings = this.commonService.settings;
    this.commonService.settingUpdate.subscribe((settings: any) => {
      this.settings = settings;
    });
  }

  ngOnInit(): void {
    this.scriptService.loadSync('site-vendor', 'site-main').then(data => {
      console.log('scripts loaded ', data);
    }).catch(error => console.log(error));
  }

  getCYear() {
    return new Date().getFullYear();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  canActivate() {
    if (this.auth.isLoggedIn()) {
      let user = this.auth.user;
      if (user.first_name !== null && user.first_name !== '') {
        return true;
      } else {
        this.router.navigateByUrl('user/profile');
        return false;
      }
    }
    this.router.navigateByUrl('auth/login');
    return false;
  }

}

import {DOCUMENT} from '@angular/common';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {Helper} from '../../../helpers/helper';
import {PropertyModel} from '../../../models/property.model';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'app-site-property-box',
  templateUrl: './property-box.component.html',
  styleUrls: ['./property-box.component.scss']
})
export class PropertyBoxComponent implements OnInit {

  @Input() property: PropertyModel;
  helper;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public commonService: CommonService,
  ) {
    this.helper = Helper;
  }

  ngOnInit(): void {

  }


}

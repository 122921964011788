<!-- navbar start -->
<div class="navbar-area">
  <nav class="navbar navbar-area navbar-expand-lg">
    <div class="container nav-container">
      <div class="responsive-mobile-menu">
        <button class="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="icon-left"></span>
          <span class="icon-right"></span>
        </button>
      </div>
      <div class="logo">
        <a routerLink="/site/index"><img src="assets/img/logo.png" alt="logo"></a>
      </div>
      <div class="nav-right-part nav-right-part-mobile">
        <a *ngIf="this.authService.isLoggedIn()" class="btn btn-yellow" routerLink="/app/index">Dashboard</a>
        <a *ngIf="!this.authService.isLoggedIn()" class="btn btn-yellow" routerLink="/auth/login">Login Now</a>
      </div>
      <div class="collapse navbar-collapse" id="realdeal_main_menu">
        <ul class="navbar-nav menu-open">
          <li class="current-menu-item"><a routerLink="/site/index">Home</a></li>
          <li class=""><a routerLink="/site/properties">Properties</a></li>
          <li><a routerLink="site/about-us">About Us</a></li>
        </ul>
      </div>
      <div class="nav-right-part nav-right-part-desktop">
        <a *ngIf="this.authService.isLoggedIn()" class="btn btn-yellow" routerLink="/app/index">Dashboard</a>
        <a *ngIf="!this.authService.isLoggedIn()" class="btn btn-yellow" routerLink="/auth/login">Login Now</a>
      </div>
    </div>
  </nav>
</div>
<!-- navbar end -->

<router-outlet></router-outlet>

<!-- footer area start -->
<footer class="footer-area">
  <div class="container">
    <div class="footer-top">
      <div class="row">
        <div class="col-sm-4">
          <a class="footer-logo" routerLink="/site/index"><img src="assets/img/footer-logo.png" alt="logo"></a>
        </div>
        <div class="col-sm-8">
          <div class="footer-social text-sm-right">
            <span>FOLLOW US</span>
            <ul class="social-icon">
              <li *ngIf="this.settings?.facebook_link">
                <a href="{{this.settings?.facebook_link}}" target="_blank"><i class="fa fa-facebook"></i></a>
              </li>
              <li *ngIf="this.settings?.instagram_link">
                <a href="{{this.settings?.instagram_link}}" target="_blank"><i class="fa fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="false" class="footer-bottom">
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="widget widget_nav_menu">
            <h4 class="widget-title">Popular Searches</h4>
            <ul>
              <li><a href="javascript:;">Apartment for Rent</a></li>
              <li><a href="javascript:;">Apartment Low to hide</a></li>
              <li><a href="javascript:;">Offices for Buy</a></li>
              <li><a href="javascript:;">Offices for Rent</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="widget widget_nav_menu">
            <h4 class="widget-title">Home Markets</h4>
            <ul>
              <li><a href="javascript:;">Los Angeles Offices</a></li>
              <li><a href="javascript:;">Las Vegas Apartment</a></li>
              <li><a href="javascript:;">Sacramento Townhome</a></li>
              <li><a href="javascript:;">San Francisco Offices</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="widget widget_nav_menu">
            <h4 class="widget-title">Quick Links</h4>
            <ul>
              <li><a href="javascript:;">Pricing Plans</a></li>
              <li><a href="javascript:;">Our Services</a></li>
              <li><a href="javascript:;">About Us</a></li>
              <li><a href="javascript:;">Contact Us</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-right text-center">© Copyright {{getCYear()}} - GoMap.</div>
  </div>
</footer>
<!-- footer area end -->

<!-- back to top area start -->
<div class="back-to-top">
  <span class="back-top"><i class="fa fa-angle-up"></i></span>
</div>
<!-- back to top area end -->

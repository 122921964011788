import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationDataModel} from '../../models/pagination-data.model';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

/**
 * pagination component
 */
export class PaginationComponent implements OnInit, AfterViewInit {


  @Input() paginationData: PaginationDataModel;
  @Input() showPageSizes = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onPageChange = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onPageSizeChange = new EventEmitter<string>();

  pageSizes = [20, 50, 100];
  pageSize;

  constructor() {
  }

  ngOnInit(): void {
    this.pageSize = this.paginationData.pageSize;
    if (this.pageSizes.includes(this.pageSize)) {
      this.pageSizes.push(this.pageSize);
    }
  }

  ngAfterViewInit(): void {
  }

  pageChange($event): void {
    this.onPageChange.emit($event);
  }

  changePagerSize(): void {
    this.onPageSizeChange.emit(this.pageSize);
  }

}

<nav
  class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl">
  <div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center">
      <ul class="nav navbar-nav ">
        <li class="nav-item">
          <a (click)="toggleMenu()" class="nav-link menu-toggle is-active" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-menu ficon">
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </a></li>
      </ul>
    </div>
    <ul class="nav navbar-nav align-items-center ms-auto">
      <li *ngIf="false" class="nav-item d-none d-lg-block"><a class="nav-link nav-link-style">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-moon ficon">
          <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
        </svg>
      </a></li>
      <li class="nav-item dropdown dropdown-user"><a class="nav-link dropdown-toggle dropdown-user-link"
                                                     id="dropdown-user" href="#" data-bs-toggle="dropdown"
                                                     aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none"><span
          class="user-name fw-bolder">{{this.authService.user.getFullName()}}</span><span
          class="user-status">{{this.authService.user.email}}</span></div>
        <span class="avatar"><img class="round" src="{{this.authService.user.getProfilePic()}}" alt="avatar"
                                  height="40" width="40"><span class="avatar-status-online"></span></span></a>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-user"><a class="dropdown-item"
                                                                                        routerLink="/user/profile">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-user me-50">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          Profile</a><a class="dropdown-item" routerLink="/user/logout">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-power me-50">
            <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
            <line x1="12" y1="2" x2="12" y2="12"></line>
          </svg>
          Logout</a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<!-- BEGIN: Main Menu-->
<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
  <div class="navbar-header expanded">
    <ul class="nav navbar-nav flex-row">
      <li class="nav-item me-auto">
        <a class="navbar-brand" href="javascript:;">
          <span class="brand-logo icon">
            <img src="assets/img/icon.png" alt="">
          </span>
          <span class="brand-logo full">
            <img src="assets/img/logo.png" alt="">
          </span>
        </a>
      </li>
      <li style="display: none;" class="nav-item nav-toggle"><a (click)="toggleMenu()"
                                                                class="nav-link modern-nav-toggle pe-0"
                                                                data-bs-toggle="collapse">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-x d-block d-xl-none text-primary toggle-icon font-medium-4">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-disc d-none d-xl-block collapse-toggle-icon primary font-medium-4">
          <circle cx="12" cy="12" r="10"></circle>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
      </a></li>
    </ul>
  </div>
  <div class="shadow-bottom"></div>
  <div class="main-menu-content">
    <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
      <!-- <li class=" nav-item"><a class="d-flex align-items-center" href="index.html"><i data-feather="home"></i><span
         class="menu-title text-truncate" data-i18n="Dashboards">Dashboards</span><span
         class="badge badge-light-warning rounded-pill ms-auto me-1">2</span></a>
         <ul class="menu-content">
           <li><a class="d-flex align-items-center" href="dashboard-analytics.html"><i data-feather="circle"></i><span
             class="menu-item text-truncate" data-i18n="Analytics">Analytics</span></a>
           </li>
           <li class="active"><a class="d-flex align-items-center" href="dashboard-ecommerce.html"><i
             data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="eCommerce">eCommerce</span></a>
           </li>
         </ul>
       </li>-->

      <li class="nav-item" [ngClass]="{'active': isActiveMenu(['/app/index'])}">
        <a
          [ngClass]="{'active': isActiveMenu(['/app/index'])}"
          class="d-flex align-items-center" routerLink="/app/index">
          <i data-feather="home"></i>
          <span class="menu-title text-truncate" data-i18n="Email">Dashboard</span>
        </a>
      </li>

      <li class="nav-item has-sub sidebar-group-active"
          [ngClass]="{'open': isActiveMenu(['/properties/create', '/properties/index'])}"
      ><a
        [ngClass]="{'active': isActiveMenu(['/properties/create', '/properties/index'])}"
        class="d-flex align-items-center" href="javascript:;" (click)="openSubMenu($event)"><i
        data-feather="grid"></i><span
        class="menu-title text-truncate" data-i18n="Dashboards">Properties</span></a>
        <ul class="menu-content">
          <li>
            <a [ngClass]="{'active': isActiveMenu(['/properties/create'])}"
               class="d-flex align-items-center" routerLink="/properties/create">
              <span class="menu-item text-truncate" data-i18n="Analytics">New Property</span>
            </a>
          </li>
          <li>
            <a [ngClass]="{'active': isActiveMenu(['/properties/index'])}"
               class="d-flex align-items-center" routerLink="/properties">
              <span class="menu-item text-truncate" data-i18n="eCommerce">View All</span>
            </a>
          </li>
        </ul>
      </li>

      <li class=" nav-item"
          [ngClass]="{'active': isActiveMenu(['/user/plans'])}"
      >
        <a
          [ngClass]="{'active': isActiveMenu(['/user/plans'])}"
          class="d-flex align-items-center" routerLink="/user/plans">
          <i data-feather="dollar-sign"></i>
          <span class="menu-title text-truncate" data-i18n="Plans">Plans</span>
        </a>
      </li>

      <li class=" nav-item"
          [ngClass]="{'active': isActiveMenu(['/user/plan-history'])}"
      >
        <a
          [ngClass]="{'active': isActiveMenu(['/user/plan-history'])}"
          class="d-flex align-items-center" routerLink="/user/plan-history">
          <i data-feather="list"></i>
          <span class="menu-title text-truncate" data-i18n="Plans History">Plans History</span>
        </a>
      </li>

      <li class=" nav-item"
          [ngClass]="{'active': isActiveMenu(['/user/profile'])}"
      >
        <a
          [ngClass]="{'active': isActiveMenu(['/user/profile'])}"
          class="d-flex align-items-center" routerLink="/user/profile">
          <i data-feather="user"></i>
          <span class="menu-title text-truncate" data-i18n="Profile">Profile</span>
        </a>
      </li>



      <li class="nav-item" [ngClass]="{'active': isActiveMenu(['/site/index'])}">
        <a
          [ngClass]="{'active': isActiveMenu(['/site/index'])}"
          class="d-flex align-items-center" routerLink="/site/index">
          <i data-feather="globe"></i>
          <span class="menu-title text-truncate" data-i18n="Email">Website</span>
        </a>
      </li>

      <li class=" nav-item">
        <a class="d-flex align-items-center" routerLink="/user/logout">
          <i data-feather="log-out"></i>
          <span class="menu-title text-truncate" data-i18n="Email">Logout</span>
        </a>
      </li>

    </ul>
  </div>
</div>
<!-- END: Main Menu-->

<!-- BEGIN: Content-->
<div class="app-content content ">
  <div class="content-overlay"></div>
  <div class="header-navbar-shadow"></div>
  <div class="content-wrapper p-0">
    <div class="content-header row">
    </div>
    <div class="content-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<!-- END: Content-->

<div class="sidenav-overlay"></div>
<div class="drag-target"></div>

<!-- BEGIN: Footer-->
<footer class="footer footer-static footer-light">
  <p class="clearfix mb-0"><span class="float-md-start d-block d-md-inline-block mt-25">COPYRIGHT  &copy; {{getCYear()}}<a
    class="ms-25" routerLink="/" target="_blank">{{env.app_name}}</a><span
    class="d-none d-sm-inline-block">, All rights Reserved</span></span><span class="float-md-end d-none d-md-block">Hand-crafted & Made with<i
    data-feather="heart"></i></span></p>
</footer>
<button class="btn btn-primary btn-icon scroll-top" type="button"><i data-feather="arrow-up"></i></button>

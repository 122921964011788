<div class="table-footer-content with-page-size">
  <div class="table-footer-summary">
    <div class="summary">Showing <b>{{paginationData.startIndex}}-{{paginationData.endIndex}}</b> of
      <b> {{paginationData.totalRecords}}</b> items.
    </div>
    <select *ngIf="showPageSizes" (change)="changePagerSize()" [(ngModel)]="pageSize" class="form-control table-footer-page-size">
      <ng-container *ngFor="let pageSize of pageSizes">
        <option [selected]="paginationData.pageSize==pageSize" value="{{pageSize}}">{{pageSize}}</option>
      </ng-container>
    </select>
  </div>
  <div class="pager">
    <ngb-pagination [maxSize]="3" class="pagination-rounded" [collectionSize]="paginationData.totalRecords"
                    (pageChange)="pageChange($event)" [(page)]="paginationData.page"
                    [pageSize]="paginationData.pageSize">
      <ng-template ngbPaginationFirst>First</ng-template>
      <ng-template ngbPaginationLast>Last</ng-template>
      <ng-template ngbPaginationPrevious>«</ng-template>
      <ng-template ngbPaginationNext>»</ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>

import { Component, OnInit, OnDestroy, HostListener, Input, Inject } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';
import {
  Router, NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  @Input() display = false;

  constructor(
    private router: Router,
    private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.commonService.showLoading();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.commonService.hideLoading();
        }
      },
      () => {
        this.commonService.hideLoading();
      }
    );
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.commonService.hideLoading();
  }

}


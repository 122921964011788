import {Injectable} from '@angular/core';
import {ApiMetaModel} from '../models/api-meta.model';
import {BehaviorSubject} from 'rxjs';
import {PaginationDataModel} from '../models/pagination-data.model';
import {ActivatedRoute} from '@angular/router';
import {PaginationResponseModel} from '../models/pagination-response.model';

@Injectable()
export class PaginationService {

  pagination$: BehaviorSubject<PaginationDataModel>;

  constructor(private route: ActivatedRoute) {
  }

  initEmptyPagination(): PaginationResponseModel {
    // tslint:disable-next-line:prefer-const
    let meta: ApiMetaModel = {
      totalCount: 0,
      pageCount: 0,
      currentPage: 0,
      perPage: 0,
    };
    const items = 0;
    this.paginationInit(meta, items);
    return {
      pagination$: this.pagination$,
      items
    };
  }

  paginationInit(meta: ApiMetaModel, length): void {
    this.pagination$ = new BehaviorSubject<PaginationDataModel>({
      _meta: meta,
      totalRecords: meta.totalCount,
      pageSize: meta.perPage,
      endIndex: length,
      startIndex: 1,
      page: 1
    });
  }

  resolveData(parameter): PaginationResponseModel {
    let items = [];
    if (this.route.snapshot.data[parameter]) {
      items = this.route.snapshot.data[parameter].items;
      this.paginationInit(
        this.route.snapshot.data[parameter]._meta,
        items.length
      );
      return {
        pagination$: this.pagination$,
        items
      };
    }
  }

  /**
   * Handle on page click event
   */
  onPageChange(page: any, meta: ApiMetaModel): void {
    const data: PaginationDataModel = {
      _meta: meta,
      totalRecords: meta.totalCount,
      pageSize: meta.perPage,
      endIndex: length,
      startIndex: 1,
      page
    };
    data.startIndex = (page - 1) * data.pageSize + 1;
    data.endIndex = (page - 1) * data.pageSize + data.pageSize;
    if (data.endIndex > data.totalRecords) {
      data.endIndex = data.totalRecords;
    }
    this.pagination$.next(data);
  }


}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocalStoreService} from './local-store.service';
import {Router} from '@angular/router';
import jwt_decode from 'jwt-decode';
import {Utils} from '../utils';
import {UserModel} from '../models/user.model';
import {Observable} from 'rxjs';
import {Helper} from '../helpers/helper';

@Injectable({providedIn: 'root'})

export class AuthService {

  user: UserModel;
  constants;

  constructor(
    private httpClient: HttpClient,
    private localStoreService: LocalStoreService,
    private router: Router,
  ) {
    this.user = this.currentUser();
    this.constants = Utils.getConstants();
  }


  public update(token): void {
    this.localStoreService.setItem('user_identity', token);
    this.isLoggedIn();
  }

  /**
   * Returns the current user
   */
  public currentUser(): UserModel {
    const userToken = this.localStoreService.getItem('user_identity');
    if (userToken != null) {
      try {
        const userData = jwt_decode(userToken);
        this.user = new UserModel();
        Object.assign(this.user, userData);
        return this.user;
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  sendOtp(fields): Observable<any> {
    return this.httpClient.post('auth/send-otp', fields);
  }

  loginWithOtp(fields): Observable<any> {
    return this.httpClient.post('auth/otp-login', fields);
  }

  login(fields): Observable<any> {
    return this.httpClient.post('auth/login', fields);
  }

  register(fields, role): Observable<any> {
    return this.httpClient.post('auth/register?role=' + role, fields);
  }

  forgotPassword(fields): Observable<any> {
    return this.httpClient.post('auth/forgot', fields);
  }

  resendActivation(fields): Observable<any> {
    return this.httpClient.post('auth/resend', fields);
  }

  resetPassword(code, fields): Observable<any> {
    return this.httpClient.post('auth/reset?c=' + code, fields);
  }

  activateAccount(token): Observable<any> {
    return this.httpClient.get('auth/activate?c=' + token);
  }

  logout(redirect: boolean = false): null {
    console.log('logout called');
    delete this.user;
    this.localStoreService.removeItem('user_identity');
    if (redirect) {
      this.router.navigateByUrl('/auth/login');
    }
    return null;
  }

  isLoggedIn(): boolean {
    const user = this.currentUser();
    if (user != null) {
      return true;
    }
    return false;
  }

}


import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from './shared/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {
    router.events.subscribe(val => {
      let pathName = window.location.pathname.toString();

      if (pathName === '/') {
        pathName = 'landing';
      } else {
        if (pathName.includes('/site')) {
          pathName = 'site';
        } else if (pathName.includes('/auth')) {
          pathName = 'site';
        } else {
          pathName = 'app';
        }
      }


      document.getElementsByTagName('html')[0].setAttribute('data-path', pathName);

      if (document.location.pathname.includes('auth')) {
        document.body.classList.add('bg-account');
        document.body.classList.add('grey-auth-design');
      } else {
        document.body.classList.remove('bg-account');
        document.body.classList.remove('grey-auth-design');
      }

      if (pathName === 'app') {
        if (document.body.offsetWidth <= 767) {
          document.body.classList.remove('menu-show');
        }
      }


    });
    this.initializeApp();
  }

  initializeApp(): void {

  }

  ngOnInit(): void {

  }

}

export class UserModel {
  public username: string;
  public email: string;
  public first_name: string;
  public last_name: string;
  public mobile_no: string;
  public user_role_id: number;

  getFullName(): string {
    return this.first_name + ' ' + this.last_name;
  }

  getProfilePic(): string {
    return 'https://ui-avatars.com/api/?background=a0a0a0&name=' + this.getFullName();
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Utils} from '../utils';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})

export class PublicService {

  constants;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.constants = Utils.getConstants();
  }

  nearbyPlaces(): Observable<any> {
    return this.httpClient.get('public/nearby-places');
  }

  countries(): Observable<any> {
    return this.httpClient.get('public/countries');
  }

  states(country_id): Observable<any> {
    return this.httpClient.get('public/states?country_id=' + country_id);
  }

  cities(state_id): Observable<any> {
    return this.httpClient.get('public/cities?state_id=' + state_id);
  }

  citiesById(city_id): Observable<any> {
    return this.httpClient.get('public/cities/' + city_id);
  }

  stateById(state_id): Observable<any> {
    return this.httpClient.get('public/states/' + state_id);
  }

  countryById(country_id): Observable<any> {
    return this.httpClient.get('public/countries/' + country_id);
  }

  contactPageDetails(): Observable<any> {
    return this.httpClient.get('public/contact-page-details');
  }

  sendContactRequest(formFields): Observable<any> {
    return this.httpClient.post('public/contact-request', formFields);
  }

  autocompletePlaces(query): Observable<any> {
    return this.httpClient.get('public/autocomplete-places?query=' + query);
  }

  plans(): Observable<any> {
    return this.httpClient.get('public/plans');
  }

  settings(): Observable<any> {
    return this.httpClient.get('public/settings');
  }

}


export const environment = {
  production: true,
  app_name: 'GoMap',
  currencyPrefix: '₹',
  apiUrl: 'https://backend.gomap.in/v1/',
  apiWebUrl: 'https://backend.gomap.in/',
  // apiUrl: 'http://localhost/arsh/data/GoMap-Backend/web/v1/',
  // apiWebUrl: 'http://localhost/arsh/data/GoMap-Backend/web/',
  app_version: '1.4',
  itemsPerPage: 20,
};
  
import {DOCUMENT} from '@angular/common';
import {Component, Inject, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-help-block-errors',
  templateUrl: './help-block-errors.component.html',
  styleUrls: ['./help-block-errors.component.scss']
})
export class HelpBlockErrorsComponent implements OnInit {

  @Input() fieldName;
  @Input() errors;

  errorsToShow = [];

  constructor(
    @Inject(DOCUMENT) private document: any,
  ) {
  }

  ngOnInit(): void {
    this.errorsToShow = [];
    console.log(this.errors);
    for (const property in this.errors) {
      if (property.toString() === 'required') {
        this.errorsToShow.push(this.fieldName + ' is required.');
      }else if (property.toString() === 'mustMatch') {
        this.errorsToShow.push(this.fieldName + ' not matched.');
      } else if (property.toString() === 'pattern') {
        this.errorsToShow.push(this.fieldName + ' is not valid.');
      }else {
        this.errorsToShow.push(this.errors[property]);
      }
    }
  }


}

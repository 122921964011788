import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {CommonService} from '../../services/common.service';


@Component({
  selector: 'app-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss']
})
export class TableLoaderComponent implements OnInit, OnDestroy {


  constructor(
    private router: Router,
    private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}


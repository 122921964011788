import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  private ls = window.localStorage;

  constructor() {
  }

  public setItem(key, value): boolean {
    this.ls.setItem(key, value);
    return true;
  }

  public getItem(key): any {
    return this.ls.getItem(key);
  }

  public removeItem(key): any {
    try {
      this.ls.removeItem(key);
    } catch (e) {
      return null;
    }
  }

  public clear(): void {
    this.ls.clear();
  }

}

import { Injectable } from '@angular/core';
import {ActivatedRoute, CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsGuestGuard implements CanActivate {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  canActivate() {
    if (this.auth.isLoggedIn()) {
      this.router.navigateByUrl('app/index');
    }
    return true;
  }

}

<div id="landing-layout-main" class="js">
  <section id="welcome">
    <article>
      <header>
        <h2>A property promotional agency.</h2>
        <p>We don't own properties, but we sure to help you provide the best properties. You can register your
          companies with us to promote them.</p>
        <p class="link-f"><a class="a" href="#">Learn More</a></p>
      </header>
      <figure><img src="assets/images/backgrounds/b.jpg" alt="Placeholder" width="1400" height="800"></figure>
    </article>
  </section>
  <div id="root">
    <header id="top" class="show">
      <h1><a href="#" accesskey="h"><img src="assets/images/logo.png" alt="Placeholder" width="178" height="53"></a></h1>
      <nav id="skip">
        <ul>
          <li><a href="#nav" accesskey="n">Skip to navigation (n)</a></li>
          <li><a href="#content" accesskey="c">Skip to content (c)</a></li>
          <li><a href="#footer" accesskey="f">Skip to footer (f)</a></li>
        </ul>
      </nav>
      <nav id="nav">
        <ul>
          <li class="active"><a href="#">Home</a></li>
          <li><a href="#">About Us</a></li>
          <li><a href="#">Clients</a></li>
          <li><a href="#">Testimonials</a></li>
          <li><a href="#">Contact</a></li>
          <li><a href="javascript:;" routerLink="/site">Properties</a></li>
        </ul>
        <ul>
          <li class="desktop-only">P: (987) 654-3210</li>
        </ul>
      </nav>
    </header>

    <router-outlet></router-outlet>

    <footer id="footer">
      <div>
        <article class="vcard">
          <h3 class="fn org"><img src="assets/images/logo-footer.png" alt="Placeholder" width="112" height="30"></h3>
          <p>P: <span class="tel">(987) 654-3210</span><br> <a class="email">info//gomap/com</a></p>
        </article>
        <nav>
          <div>
            <h3>Explore</h3>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="portfolio-grid.html">About Us</a></li>
              <li><a href="blog.html">Clients</a></li>
              <li><a href="contact.html">Contact</a></li>
              <li><a href="javascript:;" routerLink="/site/index">Properties</a></li>
            </ul>
          </div>
          <div>
            <h3>Connect</h3>
            <ul class="social">
              <li class="tw"><a href="#">Twitter</a></li>
              <li class="fb"><a href="#">Facebook</a></li>
              <li class="gl"><a href="#">Google+</a></li>
              <li class="li"><a href="#">LinkedIn</a></li>
            </ul>
          </div>
        </nav>
        <form action="#" method="post">
          <fieldset>
            <legend>Newsletter subscribe</legend>
            <p>
              <label for="na"><i class="icon-basic" data-icon="&"></i> Email</label>
              <input type="email" id="na" name="na" required>
              <button type="submit">Subscribe</button>
            </p>
          </fieldset>
        </form>
      </div>
      <p style="text-align: center">&copy;<span class="date">2014</span> GoMap, Inc. <a href="#">Privacy Policy</a>, <a
        href="#">Terms of
        Service</a></p>
    </footer>
  </div>

</div>

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalStoreService } from '../services/local-store.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private localStoreService: LocalStoreService,
  ) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    console.log(err);
    console.log('http error in ', err.status, err.url, 'type ', err.type);
    if (err.status === 401 && err.url.indexOf('login') < 0) {
      console.log('logging out');
      this.localStoreService.clear();
      this.router.navigateByUrl(`/auth/login`);
    } else {
      return throwError(err);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiReq = req.clone({
      url: environment.apiUrl + req.url,
      setHeaders: {
        Authorization: `Bearer ${this.localStoreService.getItem('user_identity')}`
      }
    });
    return next.handle(apiReq).pipe(catchError(x => this.handleAuthError(x)));
  }


}

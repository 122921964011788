import {Injectable} from '@angular/core';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';
import {Subject} from "rxjs";
import {PublicService} from "./public.service";
import {Address} from "ngx-google-places-autocomplete/objects/address";

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  public env;
  settings: any = {};
  settingUpdate: Subject<any> = new Subject<any>();

  constructor(
    private clipboardService: ClipboardService,
    private toastrService: ToastrService,
    private publicService: PublicService,
    private _sanitizer: DomSanitizer,
  ) {
    this.env = environment;
    this.loadSiteSettings();
  }

  public async loadSiteSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.publicService.settings().subscribe((res) => {
          this.settings = res.settings;
          this.settingUpdate.next(this.settings);
          return resolve(this.settings);
        },
        error => {
          this.settingUpdate.next(null);
          return resolve(null);
        });
    });
  }

  public pageTitle(title: string = '', includeAppName: boolean = true): void {
    let pageTitle = title;
    if (includeAppName) {
      pageTitle = this.env.app_name;
      if (title !== '') {
        pageTitle = title + ' | ' + pageTitle;
      }
    }
    document.title = pageTitle;
  }

  public showAlert(message: string, status: string = 'error'): void {
    switch (status) {
      case 'error':
        this.toastrService.error(message, 'Error!');
        break;
      case 'success':
        this.toastrService.success(message, 'Success!');
        break;
      case 'info':
        this.toastrService.info(message, 'Info!');
        break;
      case 'warning':
        this.toastrService.warning(message, 'Warning!');
        break;
    }
  }

  public copyToClipboard(text: string): void {
    this.clipboardService.copy(text);
    this.showAlert('Content has been copied to clipboard.', 'success');
  }

  showLoading(): void {
    console.log('show loading');
    document.body.classList.add('show-loader');
  }

  hideLoading(): void {
    console.log('hide loading');
    document.body.classList.remove('show-loader');
  }

  processError(error: any): void {
    if (error != null) {
      console.log(error);
      this.showAlert(error);
    }
  }

  bypassTrust(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }
  }

  nl2br(text: string): string {
    return text.replace(/\n/g, '<br/>');
  }

  printUnderScoreName(text: string): string {
    return text.replace(/_/g, ' ');
  }

  ifNotNull(value): boolean {
    if (value !== null && value !== 'null' && value !== '') {
      return true;
    }
    return false;
  }

  getGoogleAddressDetail(address: Address) {
    let googleSearchData: any = {};
    let addressComponents = address.address_components;
    googleSearchData['address'] = address.formatted_address;
    googleSearchData['latitude'] = address.geometry.location.lat();
    googleSearchData['longitude'] = address.geometry.location.lng();
    for (let addressComponent of addressComponents) {
      if (addressComponent.types.includes('country')) {
        googleSearchData['country'] = addressComponent.long_name;
      }
      if (addressComponent.types.includes('administrative_area_level_1')) {
        googleSearchData['state'] = addressComponent.long_name;
      }
      if (addressComponent.types.includes('administrative_area_level_2')) {
        googleSearchData['city'] = addressComponent.long_name;
      }
      if (addressComponent.types.includes('postal_code')) {
        googleSearchData['pin_code'] = addressComponent.long_name;
      }
      if (addressComponent.types.includes('locality')) {
        googleSearchData['locality'] = addressComponent.long_name;
      }
      if (addressComponent.types.includes('sublocality')) {
        googleSearchData['sub_locality'] = addressComponent.long_name;
      }
    }
    return googleSearchData;
  }


}

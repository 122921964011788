import {Component, OnInit} from '@angular/core';
import {ScriptService} from '../../../services/script.service';

@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss'],
  providers: [ScriptService]
})
export class LandingLayoutComponent implements OnInit {

  constructor(
    private scriptService: ScriptService
  ) {
  }

  ngOnInit(): void {
    this.scriptService.loadSync('jquery-v1.11.1', 'frontend-head', 'frontend-scripts', 'frontend-mobile').then(data => {
      // console.log('scripts loaded ', data);
    }).catch(error => console.log(error));
  }

}

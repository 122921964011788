interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  {name: 'jquery-v1.11.1', src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/1.11.1/jquery.min.js'},

  {name: 'frontend-head', src: '/assets/javascript/head.js'},
  {name: 'frontend-scripts', src: '/assets/javascript/scripts.js'},
  {name: 'frontend-mobile', src: '/assets/javascript/mobile.js'},

  {name: 'site-vendor', src: '/assets/javascript/site-vendor.js'},
  {name: 'site-main', src: '/assets/javascript/site-main.js'},

  {name: 'panel-vendors', src: '/assets/javascript/panel-vendors.min.js'},
  {name: 'panel-app-menu', src: '/assets/javascript/panel-app-menu.min.js'},
  {name: 'panel-app', src: '/assets/javascript/panel-app.min.js'},
  {name: 'panel-custom', src: '/assets/javascript/panel-custom.js'},

];


import {DOCUMENT} from '@angular/common';
import {Component, Inject, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-help-block-error',
  templateUrl: './help-block-error.component.html',
  styleUrls: ['./help-block-error.component.scss']
})
export class HelpBlockErrorComponent implements OnInit {

  @Input() message: string;

  constructor(
    @Inject(DOCUMENT) private document: any,
  ) {
  }

  ngOnInit(): void {

  }


}

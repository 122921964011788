export class Utils {

  static getConstants(): any {
    return {
      USER_ROLE_ADMIN: 1,
      USER_ROLE_USER: 2,
      USER_ROLE_BUILDER: 3,
    };
  }

  static getColors(): any {
    return ['#1753fc', '#9258f1', '#fdb901', '#00C851', '#11294F'];
  }

}

import {Injectable} from '@angular/core';
import {ScriptStore} from '../script.store';
import {environment} from '../../../environments/environment';

declare var document: any;

@Injectable()
export class ScriptService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      let scriptSrc = script.src.toString();
      if (!scriptSrc.includes('http')) {
        scriptSrc += '?v=' + environment.app_version;
      }
      this.scripts[script.name] = {
        loaded: false,
        src: scriptSrc
      };
    });
  }

  // will load parallel
  load(...scripts: string[]): Promise<unknown[]> {
    const promises: any[] = [];
    scripts.forEach((script) => {
      promises.push(this.loadScript(script));
    });
    return Promise.all(promises);
  }

  // will load one and one
  async loadSync(...scripts: string[]): Promise<unknown[]> {
    const promises: any[] = [];
    for (const script of scripts) {
      await this.loadScript(script).then(res => {
        promises.push(res);
      }).catch(error => {
        promises.push(error);
      });
    }
    return Promise.all(promises);
  }

  loadScript(name: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {ScriptService} from '../../../services/script.service';
import {environment} from '../../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-panel-layout',
  templateUrl: './panel-layout.component.html',
  styleUrls: ['./panel-layout.component.scss'],
  providers: [ScriptService]
})
export class PanelLayoutComponent implements OnInit {

  env;

  constructor(
    private scriptService: ScriptService,
    public authService: AuthService,
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.env = environment;
  }

  ngOnInit(): void {
    // document.getElementsByTagName('html')[0].classList.add('dark-layout');
    this.scriptService.loadSync('panel-vendors', 'panel-app-menu', 'panel-app', 'panel-custom').then(data => {
      console.log('scripts loaded ', data);
    }).catch(error => console.log(error));

    document.body.setAttribute('class', 'pace-done vertical-layout vertical-menu-modern navbar-floating footer-static menu-expanded');

    this.router.events.subscribe((val) => {
      // @ts-ignore
      if (feather) {
        // @ts-ignore
        feather.replace({width: 14, height: 14});
      }
    });

  }

  isActiveMenu(paths: string[]): boolean {
    const currentPath = window.location.pathname;
    if (paths.includes(currentPath)) {
      return true;
    }
    return false;
  }

  openSubMenu(event): void {
    const element = event.target;
    const parentElem = element.closest('li');
    if (!parentElem.classList.contains('open')) {
      parentElem.classList.add('open');
    } else {
      parentElem.classList.remove('open');
    }
  }

  toggleMenu(): void {
    if (document.body.classList.contains('menu-show')) {
      document.body.classList.remove('menu-show');
    } else {
      document.body.classList.add('menu-show');
    }
  }

  getCYear() {
    return new Date().getFullYear();
  }


}
